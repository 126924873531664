import React from "react";
import CarPurchaseForm from './CarPurchaseForm';
import s from './CarPurchaseBody.module.scss';
import { isValidCharacter } from '../../utils';




class CarPurchaseBody extends React.Component {
    state = {
        first_name: '',
        last_name: '',
        agency: '',
        phone: '',
        email: '',
        errors: {
            email: '',
            phone: '', 
        },
    }

    handleChange = (e) =>{
        let name = e.target.name;
        let value = e.target.value;
        if(isValidCharacter(name, value)){
            this.setState({
                [name]: value,
            });
        }
    }

    render(){
        const {first_name, last_name, agency, phone, email, errors} = this.state;
        return(
            <div className={s.carPurchaseBody}>
               <div className={s.blueBackground}>
                   <div className={s.textWrapper}>
                       <h1 className={s.title}>¿Sos una agencia o concesionaria?</h1>
                        <p>¡Formá parte de nuestra red y vendé más!</p>
                        <p>Ofrecemos financiamiento de calidad con cuotas accesibles, <br/> aprobación rápida y asesoramiento personalizado.</p>
                   </div>
               </div>
               <div className={s.formCard}>
                   <CarPurchaseForm first_name={first_name} last_name={last_name} agency={agency} phone={phone} email={email} errors={errors} handleChange={this.handleChange} />
               </div>
            </div>
        )
    }
}

export default CarPurchaseBody
