import React from 'react';
import s from './CarPurchaseForm.module.scss';
import Input from './Input';
import Button from './Button';


const CarPurchaseForm = ({  first_name, last_name, phone, agency, email, errors, handleChange}) => {

    return (
        <div >
            <h6 className={s.formTitle}>Sumate a nuestra red</h6>
            <Input name='first_name' value={first_name} label='Nombre' onChange={handleChange}/>
            <Input name='last_name' value={last_name} label='Apellido'  onChange={handleChange}/>
            <Input name='agency' value={agency} label='Agencia'  onChange={handleChange}/>
            <Input name='email' label='Email' value={email} className={s.inputLarge} onChange={handleChange} error={errors.email && errors.email[0]}/>
            <Input name='phone' label='Celular' type='tel' value={phone} className={s.inputLarge}  onChange={handleChange} error={errors.phone && errors.phone[0]}/>
            {errors.phone && errors.phone[0] ? null : <p className={s.legalText}>Ej.: 1160001234 o 2216000123 (Cod. de área sin el 0 + Número sin el 15)</p>}
            <div className={s.buttonsContainer}>
                <Button text='Enviar'/>
            </div>
        </div>
    )
};

export default CarPurchaseForm;